import React, {Component} from "react";
import Web3 from 'web3';
import { Grid, Row, Container, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
const download = require("downloadjs");


class Updates extends Component {

  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      this.setState({connected:true})
      this.setState({web3:web3})
    }
  }

  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      

    };
  }

    render() {
      return(


        <div className="App">
        <Container>
          <Row>
            <Col>
        <h3><b>Updates and News<br/>
        <u>Latest Version: v1.0</u></b></h3>
      
        <div className="py-4 text-center">
        <b><u>Upcoming Features (Date TBA):</u></b><br/><br/>
         - ERC20 giveaway compatibility
         <br/>- POAP Distribution System 
         <br/>- Gnosis (xDai) chain    
        </div>

        <div className="py-4 text-center">
       
        <b><u>2/7/2022 v1.0 Released:</u></b><br/><br/>
         Subscription service is now available for purchase.  
         <br/><b>v1.0 features include:</b> 
         <br/>- Polygon Network Compatibility
         <br/>- Asset Management and Account Management interfaces
        <br/>- ERC1155 and ERC721 compatibility
        <br/>- 3 varieties of giveaways
        <br/>- Decentraland collectibles detection
        <br/>- Automated Twitter giveaways
        <br/>- .csv and .txt address list compatible
        <br/>- Randomized giveaways
        <br/>Ability to archive and search for participants from previous giveaways   
        </div>
        <div className="py-4 text-center">
        <b><u>1/30/2022-2/7/2022 Beta Testing Period:</u></b><br/><br/>
         Released the beta version of the bot to selected participants for testing.     
        </div>
        </Col>
        <Col style={{textAlign:'center',paddingLeft:40}}>
       <h3 style={{textAlign:'center'}}><b><u> Current Features</u></b></h3><br/>
       - Automated Polygon Network NFT giveaways<br/>
       - ERC721 and ERC1155 bulk minting<br/>
       - ERC1155 bulk transfers<br/>
       - Automatic Twitter giveaways<br/>
       - CSV and Txt list compatible<br/>
       - NFT Contract auto-detection<br/>
       - Decentraland Collection detection<br/>
       - Archiving of past giveaways<br/>
       - Account management portal <br/>
       - Asset management<br/>
       <br/><br/>
       <button className='glow-on-hover'><Link style={{ textDecoration: 'none', color: 'grey'}} to="/purchase">Download Latest Version</Link></button>
       </Col>
        </Row>
        <br/>
       

        </Container>
      
        </div>

       
      );
    }
}

export default Updates;