import React, {Component} from "react";
import Web3 from 'web3';
import { Grid, Row, Container, Col } from 'react-bootstrap'
import License from './NFTBotLicense.json'
import '../App.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Blue from './Blue-Desktop-Icon.png';
import Red from './Red-Desktop-Icon.png';
import Yellow from './Yellow-Desktop-Icon.png';

const download = require("downloadjs");

class Purchase extends Component {

  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      const contract = new web3.eth.Contract(License.abi,"0x2863B22B570e5d02785008630C81C33a137f7C4B")
      this.setState({contract:contract})
      this.setState({connected:true})
      this.setState({web3:web3})
      await this.loadLicense()
      await this.getPrice()
      if(this.state.isLicensed){
        await this.toggleWelcome()
      }
     
    }
  }

  sign_agreement = async () => {
    const w3 = this.state.web3
    console.log("signing")
    let agreement = "\nI acknowledge that I have received and read the Terms of Service as downloaded from this website.  I hereby agree to these terms, and authorize my digital signature to verify my agreement.  I acknowledge that the terms of service may change, and that the use of the downloaded software implies my agreement to the terms."
    let message = await w3.eth.personal.sign(agreement,this.state.account)
    let nonce =  agreement
    let response = {nonce:nonce,sig:message}
    return response
  }

  download = async (event) => {
    event.preventDefault()
    const OS = event.target.os.value
    if(!this.state.connected){
      window.alert("Please connect your wallet first.")
      return
    }
    else if(OS==="none"){
      window.alert("Please specify your operating system")
      return
    }
  
    const tos = await this.getTOS()
    if(!tos){
      return
    }
    window.alert("Please Read the Terms of Service")
    const signed_agreement = await this.sign_agreement()
  
    let body = {address: this.state.account,signed_agreement: signed_agreement,os: OS}
    const res = await fetch("https://nftrobots.net/server/request-download/",{mode:"cors",method:"POST",headers: {'Accept': 'application/json','Content-Type': 'application/json'},body:JSON.stringify(body)})
    if(res['status'] === 200){
        const blob = await res.blob()
        download(blob,'NFT-Distribution-Bot.zip')}
    else{
      window.alert('You do not have an active license.  Request denied.')}
  }

  getTOS = async () => {
    const response = await fetch("https://nftrobots.net/server/end-user-agreement/",{method:"GET"})
    if(response['status']===200){
      const blob = await response.blob()
      const pdfWindow = await window.open()
      const fileURL = URL.createObjectURL(blob)
      pdfWindow.location.href=fileURL
      return true
    }
    else {
      window.alert("Error getting Terms of Service -- Please try again later.")
      return false
    }
  }

  purchase = async(event) =>{
    if(!this.state.connected){
      window.alert("Please connect your wallet first.")
      return
    }
    if(this.state.selected==='days'){
      if(this.state.trial===true){
      window.alert("You are no longer eligible for the trial.  Please purchase 1 week or longer.")
      return}
    }
    event.preventDefault()
    const matic_value = this.state.cost*this.state.duration/this.state.price
    const contract = this.state.contract
    const w3 = this.state.web3
    const value = w3.utils.toWei(matic_value.toString(),'ether')
    this.setState({purchasing:true})
    contract.methods.purchase().send({from:this.state.account,value:value})
    
    await this.loadLicense()
    await this.waitForReceipt()
    this.setState({purchasing:false})
    
  
  }

  submitDelegation = async(event) =>{
    event.preventDefault()
    if(!this.state.connected){
      window.alert("Please connect your wallet first.")
      return
    }
    const contract = this.state.contract
    const delegate = event.target.address.value
    const address = this.state.account
    this.setState({delegating:true})
    contract.methods.delegateAddress(delegate).send({from:address})
    await this.waitForDelegation()
    this.setState({delegating:false})
  }
  

  loadLicense = async() =>{
    const contract = this.state.contract
    const isLicensed = await contract.methods.isLicensed(this.state.account).call()
    const timestamp = await contract.methods.getTimestamp(this.state.account).call()
    const delegate = await contract.methods.getDelegate(this.state.account).call()
    const trial = await contract.methods.checkTrial(this.state.account).call()
    console.log('Trial',trial.toString())
    this.setState({isLicensed:isLicensed,timestamp:timestamp,delegate:delegate,trial:trial})
  }
  handleSelect = async(event) =>{
    event.preventDefault()
    const selected = event.target.value
    await this.setState({selected:selected})
    await this.updateCost()
  }

  handleDuration = async(event) => {
    event.preventDefault()
    const duration = event.target.value
    await this.setState({duration:duration})
    await this.updateCost()
    
  }
  handleOS = async(event) => {
    event.preventDefault()
    const os = event.target.value
    await this.setState({os:os})
  }

  updateCost = async() =>{
    const duration = this.state.duration
    if(this.state.selected==='days'){
      if(duration>1){
        
        window.alert("You can only have a 1 day trial")
        return
      }
      const cost = 5.00
      this.setState({cost:cost})
    }else if(this.state.selected==="week(s)"){
      if(duration>3){
        window.alert("You should switch to monthly if you would like more than 3 weeks")
        return
      }
      const cost = 20.00*duration
      this.setState({cost:cost})
    }else if(this.state.selected==="month(s)"){
      if(duration>2){
        window.alert("You should switch to quarterly for more than 2 months.")
        return
      }
      const cost = 75*duration
      this.setState({cost:cost})
    }
    else if(this.state.selected==="quarter(s)"){
      if(duration>3){
        window.alert("You should switch to yearly for more then 3 quarters.")
        return
      }
      const cost = 175*duration
      this.setState({cost:cost})
    }
    else if(this.state.selected==="year(s)"){
      const cost = 650*duration
      this.setState({cost:cost})
    }
  }
  getPrice = async() =>{
    let res = await fetch("https://api.coinbase.com/v2/prices/MATIC-USD/sell")
    let response = await res.json()
    this.setState({price: response.data.amount})
  }

  toggleModal = async(event) => {
    const open = this.state.open
    this.setState({open:!open})
    
  }

  toggleModal2 = async(event) => {
    const open = this.state.open2
    this.setState({open2:!open})
    
  }
  
  closeModal = async(event) => {this.setState({open:false})}

  modalDownload = async(event) => {
    this.download(event)
    this.toggleModal()
  }

  welcomeDownload = async(event) => {
    this.download(event)
    this.toggleWelcome()
  }

  toggleWelcome = async(event) =>{
    const welcome = this.state.welcome
    this.setState({welcome:!welcome})
  }

  waitForReceipt = async() => {
    console.log('checking events')
    const contract = this.state.contract
    const web3 = this.state.web3
    
    let purchaseEvent = false;
    while(!purchaseEvent){
      const block = await web3.eth.getBlockNumber()
      let events = await contract.getPastEvents('LicensePurchased',{fromBlock:block-5,toBlock:'latest'})
      if(events){
        
        for(let i=0;i<events.length;i++){
          console.log(events[i])
          if(events[i]['returnValues']['buyer'] === this.state.account){
            purchaseEvent=true;
            this.toggleModal()
            return;
          }
        }  
      }
    }     
  }

  waitForDelegation = async() => {
    console.log('checking events')
    const contract = this.state.contract
    const web3 = this.state.web3
    let delegateEvent = false;
    while(!delegateEvent){
      const block = await web3.eth.getBlockNumber()
      let events = await contract.getPastEvents('Delegation',{fromBlock:block-5,toBlock:'latest'})
      if(events){
        
        for(let i=0;i<events.length;i++){
          console.log(events[i])
          if(events[i]['returnValues']['owner'] === this.state.account){
            delegateEvent=true;
            this.toggleModal2()
            return;
          }
        }  
      }
    } 
  }

  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      contract: null,
      isLicensed: false,
      timestamp: false,
      selected: 'days',
      cost:5.00,
      duration: 1,
      os: "none",
      open: false,
      purchasing: false,
      delegating: false,
      open2: false,
      welcome: false,
      trial:false,
    };
  }
  componentDidMount() {
   this.getPrice()
    
  }

    render() {
      
      let connection_message = <text className='text-danger'>{"  Not Connected           "}</text>
      let connection_class = 'animated-button'

      if(this.state.connected){
        connection_message = <text className='text-success'>{"  Connected to:  " + this.state.account.slice(0,6)+'...'+this.state.account.slice(this.state.account.length-4)}</text>
        connection_class = 'base-button'
      }



      let licenseStatus = <text className='text-danger'>{"  Not Active"}</text>
      if(this.state.isLicensed){
        licenseStatus = <text className='text-success'>{"  Active/Valid"}</text>
      }
      
      let year = ""
      let month=""
      let day = ""
      let hour = ""
      let minute = ""
      let date_message = ""
      if(this.state.timestamp && this.state.isLicensed){
        const date = new Date(this.state.timestamp*1000)
        
        year = date.getFullYear().toString()
        month = (date.getMonth()+1).toString()
        day = date.getDate().toString()
        hour = date.getHours()
        if(hour<10){
          hour = '0'+hour.toString()
        }
        minute = date.getMinutes().toString()
        if(minute<10){
          minute='0'+minute.toString()
        }
        hour=hour.toString()
        minute=minute.toString()
       
        date_message =<text> <b>{" " + month+ "/"+day +"/"+ year}</b>{" at "}<b>{ hour+":"+minute}</b></text>
      }

      let duration_message = <text>{"  ("+this.state.selected+")"}</text>
      
      let cost_message = <text><b>{+(this.state.cost/this.state.price).toFixed(2).toString()+ " MATIC   ( $"+ this.state.cost.toString()+" USD)"}</b></text>
      let purchase_button = <text>{"Submit Purchase"}</text>
      if(this.state.purchasing){
        purchase_button = <text>{"Processing..."}</text>
      }
      let delegate_button = <text>{"Submit Delegation"}</text>

      if(this.state.delegating){
        delegate_button = <text>{"Processing..."}</text>
      }

      
      
     
      return(

        
        <div className="App">
         
        <Container>

        <div>
      
      <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
        <div style={{textAlign:'center'}}>
          <text>CONGRATULATIONS</text><br/><br/>
          <img height='100' width='100' src={Blue} alt='Parachute1'></img>
          <img height='100' width='100' src={Red} alt='Parachute2'></img>
          <img height='100' width='100' src={Yellow} alt='Parachute3'></img>
          <br/>
          <br/>
          Your transaction has been processed!<br/>

          <form onSubmit={this.modalDownload}>
          <select name="os" id="os"><option value="none">Choose Operating System</option><option value="Windows">Windows 10, 11</option><option value="Linux">Linux Ubuntu 20</option><option value="MacOS">MacOS Big Sur, Catalina</option> </select>
      
        <button className="animated-button" type="submit">Click to download</button> 
        </form>
        **Note** It can take a moment for the purchase to process on-chain.  If your download doesn't immediately work, wait a few minutes and try the download button on the main page. 
        </div>
      </Popup>
        </div>


        <div>
      
      <Popup open={this.state.open2} closeOnDocumentClick >
        <div style={{textAlign:'center'}}>
          <text>CONGRATULATIONS</text><br/><br/>
          <img height='100' width='100' src={Blue} alt='Parachute1'></img>
          <img height='100' width='100' src={Red} alt='Parachute2'></img>
          <img height='100' width='100' src={Yellow} alt='Parachute3'></img>
          <br/>
          <br/>
          Delegation has been processed!<br/>
          Your delegate now has access to your subscription!
          <br/>
          <button className="animated-button" onClick={this.toggleModal2}>Close</button>
        </div>
      </Popup>
        </div>

        
        <div>
      
      <Popup open={this.state.welcome} closeOnDocumentClick >
        <div style={{textAlign:'center'}}>
          <text>WELCOME BACK!!!</text><br/><br/>
          <img height='100' width='100' src={Blue} alt='Parachute1'></img>
          <img height='100' width='100' src={Red} alt='Parachute2'></img>
          <img height='100' width='100' src={Yellow} alt='Parachute3'></img>
          <br/>
          <br/>
          This account has an active subscription - thank you for your support!<br/>
          <form onSubmit={this.welcomeDownload}>
          <select name="os" id="os"><option value="none">Choose Operating System</option><option value="Windows">Windows 10, 11</option><option value="Linux">Linux Ubuntu 20</option><option value="MacOS">MacOS Big Sur, Catalina</option> </select>
      
        <button className="animated-button" type="submit">Click to download</button> 
        </form>
          <br/>
          <button className="base-button" onClick={this.toggleWelcome}>Close</button>
          <br/>
          **Note** If this download button doesn't work for you, then try the one on the main page.  Thank you!
        </div>
      </Popup>
        </div>




        <button className={connection_class} onClick={this.loadWeb3}><span></span><span></span><span></span><span></span>Connect Wallet</button><br/><br/>{connection_message}  <br/>
        <Row className="py-5">
        <Col style={{float:"left",textAlign:"left"}}>
          <h3><b><u>Your License Information</u></b></h3>
        Subscription Status:
        {licenseStatus}<br/><br/>
        
        Expires on{ date_message } <br/><br/>
        Delegate: {this.state.delegate}<br/><br/>
        <form onSubmit={this.download}>
        <select onChange ={this.handleOS} name="os" id="os"><option value="none">Choose Operating System</option><option value="Windows">Windows 10, 11</option><option value="Linux">Linux Ubuntu 20</option><option value="MacOS">MacOS Big Sur, Catalina</option> </select>
       <br/>
        <br/>
        <button className='glow-on-hover' type="submit">Download V1.0</button>
        </form>
        <br/>
        <br/>
        <br/>
        <br/>
        <b><u>Delegate your license</u></b>
        <br/>
        <form onSubmit={this.submitDelegation}>
          <input type='text' id='address' name='address' placeholder="Insert Delegate Address"></input><br/><br/>
          <button className="glow-on-hover" type='submit'>{delegate_button}</button>
        </form>
        </Col>

        <Col style={{float:"center",textAlign:"left"}}>
        <h3><b><u>Subscription Purchases</u></b></h3>
        
        - We handle subscription purchases with a smart contract membership registry.  All transaction payments are submitted with Polygon MATIC.  
        <i>You must have your wallet connected to the Polygon network to make a purchase. </i>  Once your payment is validated and posted to the 
        blockchain (usually less than a minute after you submit) you will be prompted to sign the software terms-of-service, after signing you will
        receive the download for the latest version.  If you have an active subscription, you can always download the latest version of the software
        from this site. <i>**Note** Sometimes transactions can take a while, you can always speed them up by choosing the "Speed up" option in your wallet.</i>
        <br/>
        <br/>
        Fill out the following form to prompt a purchase
        <br/>
        <form onSubmit={this.purchase}>
            <label for="tiers">Choose a Tier:</label><br/>
            <select onChange={this.handleSelect} id="tiers" name="tiers" >
                <option value="days">Trial (One-Day)</option>
                <option value="week(s)">Weekly</option>
                <option value="month(s)"> Monthly</option>
                <option value="quarter(s)">Quarterly</option>
                <option value="year(s)">Yearly</option>
            </select><br/>
            <label for="duration">Duration</label><br/>
            <input  onChange={this.handleDuration} type="number" id="duration" name="duration" min="1" max='6' placeholder={1}></input>{duration_message}
            <br/>
            <br/>
            Price: {cost_message}
            <br/>
            <br/>
            <button className="glow-on-hover" type="submit">{purchase_button}</button>
            <br/>
           
        </form>
        </Col>
        </Row>
        </Container>
      
        </div>
        
       
      );
    }
}

export default Purchase;