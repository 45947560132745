import React, {Component} from "react";
import Web3 from 'web3';
import { Link } from 'react-router-dom';

import { Grid, Row, Container, Col } from 'react-bootstrap'
import YouTube from 'react-youtube'

const download = require("downloadjs");



class Home extends Component {
  
  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      this.setState({connected:true})
      this.setState({web3:web3})
    }
  }

  get_message = async () => {
    const res = await fetch("/license/"+this.state.account)
    const blob = await res.blob()
    download(blob,'File.json')
  }

  sign_agreement = async () => {
    const w3 = this.state.web3
    console.log("signing")
    let date = new Date().toLocaleDateString()
    let message = await w3.eth.personal.sign(date + "\n I acknowledge that I have received and read the Terms of Service as downloaded from this website.  I hereby agree to these terms, and authorize my digital signature to verify my agreement.  In the event of a dispute, I agree to use the following SHA-256 hash to identify the Terms of Service document: 0x52109eE547F7611f4207E2873aDc6B31BBC1428d0x52109eE547F7611f4207E2873aDc6B31BBC1428d0x52109eE547F7611f4207E2873aDc6B31BBC1428d0x52109eE547F7611f4207E2873aDc6B31BBC1428d0x52109eE547F7611f4207E2873aDc6B31BBC1428d0x52109eE547F7611f4207E2873aDc6B31BBC1428d",this.state.account)
    console.log('signed')
  }

 


  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      

    };
  }

    render() {
      return(


        <div className="App">
          
        <h3>Welcome to the Home of the NFT Distribution Bot -- V1.0 Now Available!!!</h3> 
        <Container>
        <br/>
          <Row>
           
        <Col >
        
        <Row>
        <YouTube videoId = {"QzqdrIRErHA"} opts={{height:'600',width:'900'}}></YouTube>
        </Row>
 
       </Col>

       <Col style={{textAlign:'left',paddingLeft:40}}>
       <h4 style={{textAlign:'center'}}><b><u> Features</u></b></h4><br/>
       - Automated Polygon Network NFT giveaways<br/><br/>
       - ERC721 and ERC1155 bulk minting<br/><br/>
       - ERC1155 bulk transfers<br/><br/>
       - Automatic Twitter giveaways<br/><br/>
       - CSV and Txt list compatible<br/><br/>
       - NFT Contract auto-detection<br/><br/>
       - Decentraland Collection detection<br/><br/>
       - Archiving of past giveaways<br/><br/>
       - Account management portal <br/><br/>
       - Asset management<br/>

       </Col>
           </Row>
           <br/>
           <button className='glow-on-hover'><Link style={{ textDecoration: 'none',color:'grey' }} to='/subscriptions'>Read About Subscriptions</Link></button><br/><br/>
        </Container>
      
        </div>

       
      );
    }
}

export default Home;