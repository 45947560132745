import React, {Component} from "react";
import Web3 from 'web3';
import { Grid, Row, Container, Col } from 'react-bootstrap'
import { send } from 'emailjs-com'
import { SocialIcon } from 'react-social-icons';

class Contact extends Component {

    onSubmit = (e) => {
        e.preventDefault();
        send(
          'SERVICE ID',
          'TEMPLATE ID',
          'tosend',
          'User ID'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };



  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      

    };
  }
  // <form>
  //<input type='text' size="78" name='Email' placeholder='Your Email'/><br/>
  //<input type='text' size="78" name='' placeholder='Subject'/><br/>
  //<textarea cols="80" rows='20' type='text' name='message' placeholder='Your Message'/><br/>
  //<button className='glow-on-hover'>Send Email</button>



    render() {
      return(


        <div className="App">
        <Container style={{textAlign:'left'}}>
            <h3>Need help or have a question for us?  Contact us and we will get back as soon as possible!</h3>
            <br/>
            <br/>
            <SocialIcon url="https://twitter.com/mrdrhamm" /> @MrDrHamm
            <br/>
            <br/>
      
            <SocialIcon url="https://www.youtube.com/channel/UC9nc5b0C51cUIJnstDnKn6g/"/> Youtube <br/>
            <br/>
            <SocialIcon url="mailto:MrDrHammLLC@gmail.com" network="email"/> Email Us
        </Container>
        
        </div>
      );
    }
}
export default Contact;