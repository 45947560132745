import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import { Home, Navigation, Subscriptions, Purchase, Updates, Contact, Beta } from "./components";

function App() {
  return (
    <div className="App" style={{fontFamily:"Times New Roman",fontSize:18}}>

      <Router>
        <Navigation/>  
        <Switch>
          <Route path="/" exact component = {() => <Home/>}/>
          <Route path="/purchase" exact component = {() => <Purchase/>}/>
          <Route path="/updates" exact component = {() => <Updates/>}/>
          <Route path="/subscriptions" exact component = {() => <Subscriptions/>}/>
          <Route path="/contact" exact component = {() => <Contact/>}/>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
