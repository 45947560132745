import React, {Component} from "react";
import Web3 from 'web3';
import { Link } from 'react-router-dom';
import { Grid, Row, Container, Col } from 'react-bootstrap'
const download = require("downloadjs");


class Subscriptions extends Component {

  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      this.setState({connected:true})
      this.setState({web3:web3})
    }
  }

  getTOS = async () => {
    
    const res = await fetch("https://nftrobots.net/server/end-user-agreement/",{method:"GET"})
    if(res['status']===200){
      const blob = await res.blob()
      const pdfWindow = window.open()
      const fileURL = URL.createObjectURL(blob)
      pdfWindow.location.href=fileURL
      return true

      
    }
    else {
      window.alert("Error getting Terms of Service -- Please try again later.")
      return false
    }
  }

  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      

    };
  }

    render() {
      return(


        <div className="App">
        <Container>
        <br/>
        <h3><b><u>How Do Subscriptions Work?</u></b></h3>
        The NFT Distribution Bot software is activated with a time-based blockchain subscription service.  All subscription tiers enable all current features of the software (in the future, there may be 
        tier dependent features).  Subscriptions are time-based.  After your subscription term ends, the software will disable most of its features until you purchase a new subscription.  We allow you to 
        delegate an active subscription to a single other account, allowing it to access the primary features.  Prices will always be reflected in USD, but <b><i>must be paid with Polygon MATIC</i></b>.  Subscriptions will enable you to 
        download the latest updated versions - including new and updated features - of the software as they come out.  
        <br/><br/>
        <h3><b><u>Subscription Tiers</u></b></h3>
        

        <div className="py-3 text-center">
        <b><u>Trial Tier</u></b><br/>
        - $5 USD.  One time one day trial period.  You can purchase this a single time to test the software and decide whether or not you like it.    
        </div>
        <div className="py-3 text-center">
        <b><u>Weekly Tier</u></b><br/>
        - $20 USD per week (7 days).  Maximum of 3 weeks extension per purchase.
        </div>
        <div className="py-3 text-center">
        <b><u>Monthly Tier</u></b><br/>
        - $75 USD per month (30 days).  Maximum of 2 months extension per purchase (3 months defaults to the quarterly tier).
        </div>
        <div className="py-3 text-center">
        <b><u>Quarterly Tier</u></b><br/>
        - $175 per quarter (91 days).  Maximum of 3 quarters per purchase.  
        </div>
        <b><u>Yearly Tier</u></b><br/>
        - $650 per year (365 days).  All purchases which exceed this value will be valued at $1.78 per day or a flat $650 per year.  
        <br/><br/>

        <button  className='glow-on-hover' onClick={this.getTOS}>Terms of Service</button><br/><br/>
        <button className='glow-on-hover'><Link style={{ textDecoration: 'none',color:'grey' }} to='/purchase'>Purchase</Link></button>
        </Container>
      
        </div>

       
      );
    }
}

export default Subscriptions;