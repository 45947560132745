import React, {Component} from "react";
import Web3 from 'web3';
import { Grid, Row, Container, Col } from 'react-bootstrap'
const download = require("downloadjs");


class Beta extends Component {

  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      this.setState({connected:true})
      this.setState({web3:web3})
    }
  }

  get_message = async () => {
    const res = await fetch("/license/"+this.state.account)
    const blob = await res.blob()
    download(blob,'File.json')
  }

  sign_agreement = async () => {
    const w3 = this.state.web3
    console.log("signing")
    let agreement = "\nI acknowledge that I have received and read the Terms of Service as downloaded from this website.  I hereby agree to these terms, and authorize my digital signature to verify my agreement.  I acknowledge that the terms of service may change, and that the use of the downloaded software implies my agreement to the terms."
    let message = await w3.eth.personal.sign(agreement,this.state.account)
    let nonce =  agreement
    let response = {nonce:nonce,sig:message}
    return response
  }

  download = async (event) => {
    event.preventDefault()
    const OS = event.target.os.value
    if(!this.state.connected){
      window.alert("Please connect your wallet first.")
      return
    }
    else if(OS==="none"){
      window.alert("Please specify your operating system")
      return
    }
    console.log(OS)
    const tos = await this.getTOS()
    if(!tos){
      return
    }
    window.alert("Please Read the Terms of Service")
    const signed_agreement = await this.sign_agreement()
  
    let body = {address: this.state.account,signed_agreement: signed_agreement,os: OS}
    const res = await fetch("https://nftrobots.net/server/request-download/",{mode:"cors",method:"POST",headers: {'Accept': 'application/json','Content-Type': 'application/json'},body:JSON.stringify(body)})

    if(res['status'] === 200){
        const blob = await res.blob()
        download(blob,'NFT-Distribution-Bot.zip')}
    else{
      window.alert('You are not approved to participate in the Beta')}
  }

  getTOS = async () => {
    const res = await fetch("https://nftrobots.net/server/end-user-agreement/",{method:"GET"})
    if(res['status']===200){
      const blob = await res.blob()
      const pdfWindow = window.open()
      const fileURL = URL.createObjectURL(blob)
      pdfWindow.location.href=fileURL
      return true
    }
    else {
      window.alert("Error getting Terms of Service -- Please try again later.")
      return false
    }
  }

  constructor(props) {
    super(props);
    this.state = {

      message: "Some Welcome Text!",
      account: "",
      connected: false,
      web3: null,
      networkId: 0,
      

    };
  }

    render() {

      let connectionMessage = <p className="text-danger"><b>Not Connected </b></p>
      if (this.state.account){
          connectionMessage = <p className="text-success"><b>{this.state.account}</b> </p>
      }

      
      return(


        <div className="App">
        <Container>
        Beta Testing Info<br/>
        <br/><br/>

        <div className="py-5 text-center">
        
        - Hi, welcome to the Beta Test for the NFT Giveaway Bot.  If you have been selected as a tester, then you already know why you are here and what to do.  
        If you stumbled across this website and were hoping to give the bot a test, then we apologize, the beta test is being done privately and all testers have
        already been selected.  Watch out for the upcoming release of V1.0 of the bot, staged for release shortly after the beta.  We hope to see you again soon!  
        <br/>
        <br/>
        Thanks, have a great day, and happy testing! 
        <br/>
        -- MrDrHamm
        </div>
        {connectionMessage}
        <b>Account: </b>{"   "+this.state.account.slice(0,6)+"..."+this.state.account.slice(this.state.account.length-4,this.state.account.length)}
        <br/>
        <button onClick={this.loadWeb3}> Connect Wallet</button><br/><br/>
       
       <form onSubmit={this.download}>
       <select name="os" id="os"><option value="none">Choose Operating System</option><option value="Windows">Windows 10+</option><option value="Linux">Linux Ubuntu 20</option><option value="MacOS">MacOS Big Sur</option> </select>
        <button type='submit'>Download Beta</button><br/>
        </form>

        <br/><br/>
        <button onClick={this.getTOS}>View Terms of Service</button><br/>
        </Container>
      
        </div>

       
      );
    }
}

export default Beta;